<template>
    <section>
    <div class="container">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div style="padding-top:60px; text-align: center;" class="shadow-lg p-3 mb-5 bg-body rounded">

                    <div>
                        <h4>Register</h4>
                    </div>
                    <p>{{ message }}</p>
                   <form @submit.prevent="login()">

                    <div class="row">

                    <div class="col-md-6">
                    <input type="text" class="form-control input-design" v-model="first_name" placeholder="First name" :class="{ show_error : show_error }">
                    </div>

                    <div class="col-md-6">
                    <input type="text" class="form-control input-design" v-model="last_name" placeholder="Last name" :class="{ show_error : show_error }">
                    </div>

                    <div class="col-md-6">
                    <input type="email" class="form-control input-design" v-model="email" placeholder="Email address" :class="{ show_error : show_error }">
                    </div>

                    <div class="col-md-6">
                    <input type="text" class="form-control input-design" v-model="phone" placeholder="Phone number" :class="{ show_error : show_error }">
                    </div>

                    <div class="col-md-12">
                    <input type="text" class="form-control input-design" v-model="mode_of_communication" placeholder="Your preferred mode of communication. eg call,sms,etc" :class="{ show_error : show_error }">
                    </div>

                    

                    <div class="col-md-12">
                    <input type="password" class="form-control input-design" v-model="password" placeholder="Password" :class="{ show_error : show_error }">
                    </div>


                    <div class="col-md-12" style="padding-top:20px;">
                  <button class="btn theme-btn">Register</button>
                    </div>

                    </div>

                   </form> 
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'Home',
    data() {
        return {
            first_name : '',
            last_name : '',
            email : '',
            phone : '',
            password : '',
            show_error : false,
            message : '',
            mode_of_communication : ''
        }
    },
    methods : {
        async login(){
            if(!this.email){
                this.show_error = true
                return
            }

            if(!this.password){
                this.show_error = true
                return
            }

            if(!this.first_name){
                this.show_error = true
                return
            }

            if(!this.last_name){
                this.show_error = true
                return
            }

            if(!this.phone){
                this.show_error = true
                return
            }

            if(!this.mode_of_communication){
                this.show_error = true
                return
            }

            this.show_error = false

            const res = await axios.post(this.$store.state.api_url+'api/register',{
                email : this.email,
                password : this.password,
                phone : this.phone,
                first_name : this.first_name,
                last_name : this.last_name,
                mode_of_communication : this.mode_of_communication
            }).then(function(response){
                console.log(response.data)
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.message = res

            setTimeout(() => {
               this.$router.push('/login') 
            }, 2000);
        }
    }
}
    


</script>

<style scoped>

.show_error{
    border-bottom: solid 1px red;
}

</style>