<template>
    <section>
    <div class="container">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div style="padding-top:60px; text-align: center;" class="shadow-lg p-3 mb-5 bg-body rounded">
                    <div>
                        <h4>Login</h4>
                    </div>
                    <p>{{ message }}</p>
                   <form @submit.prevent="login()">
                   <div>
                    <input type="email" class="form-control input-design" v-model="email" placeholder="Email address" :class="{ show_error : show_error }">
                   </div>

                   <div style="padding-top:0px;">
                    <input type="password" class="form-control input-design" v-model="password" placeholder="password" :class="{ show_error : show_error }">
                   </div>

                   <div style="padding-top:20px;">
                    <button class="btn theme-btn">Login</button>
                   </div>


                   </form> 
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'Home',
    data() {
        return {
            email : '',
            password : '',
            show_error : false,
            message : ''
        }
    },
    methods : {
        async login(){
            localStorage.clear();
            if(!this.email){
                this.show_error = true
                return
            }

            if(!this.password){
                this.show_error = true
                return
            }
            this.show_error = false

            const res = await axios.post(this.$store.state.api_url+'api/login',{
                email : this.email,
                password : this.password
            }).then(function(response){
                console.log(response.data)
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            
            if(res=='fail'){
                this.message = 'Wrong email address or password'
            }else{
                res.forEach(data => {
                    localStorage.setItem('first_name',data.first_name)
                    localStorage.setItem('last_name',data.last_name)
                    localStorage.setItem('email',data.email)
                    localStorage.setItem('phone',data.phone)
                    localStorage.setItem('password',data.password)
                    localStorage.setItem('logged_in',true)
                    localStorage.setItem('user_id',data.id)
                    localStorage.setItem('user_type',data.user_type)
                    this.$store.state.user_type = data.user_type
                });
                this.$store.state.logged_in = true
                this.$router.push('/home')
            }
        }
    }
}
    


</script>

<style scoped>

.show_error{
    border-bottom: solid 1px red;
}


</style>