<template>
    <section>
    <div class="container">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div style="padding-top:60px; text-align: center;" class="shadow-lg p-3 mb-5 bg-body rounded">
                    <div>
                        <img src="assets/images/loader-2.gif" alt="" style="height: 100px;">
                    </div>
                    <h5>Please Wait...</h5>
                    <h4>We are confirming your order</h4>

                  
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
    </div> 
    </section>
</template>

<script>

export default {
    name : 'ConfirmOrder',
    data() {
        return {}
    },
    methods : {},
    created (){

        setTimeout(() => {
          this.$router.push('/success')  
        }, 5000);
    }
}
    


</script>

<style scoped>

</style>