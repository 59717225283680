<template>
    <section>
    <div class="container shadow-lg p-3 mb-5 bg-body rounded">
        <div style="padding-bottom:10px;">
            <router-link to="/dashboard" class="btn theme-btn">Go Back</router-link>
        </div>
        <div class="row">

            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Pickup location</p>
                <h6> {{ detail.pickup_location }} </h6>
            </div>
            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Destination</p>
                <h6> {{ detail.destination }} </h6>
            </div>

            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Amount</p>
                <h6>Ksh. {{ detail.amount }} </h6>
            </div>

            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Date</p>
                <h6> {{ detail.created }} </h6>
            </div>

            <div class="col-md-4 box" v-for="detail in details">
                <p style="color:#800;">Time driver arrived</p>
                <h6> {{ detail.time_driver_arrived }} </h6>
            </div>

            <div class="col-md-4 box" v-for="detail in details">
                <p style="color:#800;">Time driver dropped</p>
                <h6> {{ detail.time_driver_dropped }} </h6>
            </div>

            <div class="col-md-4 box" v-for="detail in details">
                <p style="color:#800;">Driver name</p>
                <h6> {{ detail.driver_name }} </h6>
            </div>

            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Client name</p>
                <h6> {{ detail.first_name }} {{ detail.last_name }} </h6>
            </div>

            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Client email</p>
                <h6> {{ detail.email }}  </h6>
            </div>

            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Client phone</p>
                <h6> {{ detail.phone }} </h6>
            </div>

            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Order completed</p>
                <h6> {{ detail.completed }} </h6>
            </div>


            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Car type</p>
                <h6> {{ detail.car_type }} </h6>
            </div>

            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Registration number</p>
                <h6> {{ detail.reg_number }} </h6>
            </div>

            <div class="col-md-3 box" v-for="detail in details">
                <p style="color:#800;">Car type</p>
                <h6> {{ detail.car_model }} </h6>
            </div>

        </div>

        <div class="row">

    <div style="padding-top:10px;" class="col-md-6">
            <button class="btn theme-btn-2" @click="complete_ride()">Complete ride</button>
       <button class="btn theme-btn" @click="delete_ride()">Delete ride</button>
        </div>

            <div class="col-md-6" style="padding-top:10px;">
                <button class="btn theme-btn-2" @click="show_cars=!show_cars">Allocate car</button>
            </div>
        </div>



        <div style="padding: 20px; border: solid 1px lightgrey; margin-top: 20px;" v-if="show_cars">
            <h4>Allocate car to trip</h4>

            <table class="table">
                <thead>
                    <tr> <th>Reg number</th> <th>Model</th> <th>Type</th> <th>Action</th> </tr>
                </thead>

                <tbody v-for="car in cars">
                    <tr> <td> {{ car.reg_number }} </td> <td> {{ car.car_model }} </td> <td> {{ car.car_type }} </td> <td> <button class="btn theme-btn-2" @click="allocate_car(car.reg_number,car.car_model)">Allocate</button> </td> </tr>
                </tbody>

            </table>

        </div>

    </div> 


    <div class="container shadow-lg p-3 mb-5 bg-body rounded">

        <form @submit.prevent="order_changes()">
            <div  class="row">
                <div class="col-md-6">
                    <label>Amount</label>
                    <input type="text" class="form-control" v-model="amount">
                </div>

                <div class="col-md-6">
                    <label>Time driver arrived</label>
                    <input type="time" class="form-control" v-model="time_driver_arrived">
                </div>

                <div class="col-md-6">
                    <label>Time driver  dropped</label>
                    <input type="time" class="form-control" v-model="time_driver_dropped">
                </div>

                <div class="col-md-6">
                    <label>Driver name</label>
                    <input type="text" class="form-control" v-model="driver_name">
                </div>

                <div class="col-md-6" style="padding-top:20px;">
                   <button class="btn theme-btn" type="submit">Save changes</button>
                </div>

            </div>
        </form>

    </div>



    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'OrderDetails',
    data() {
        return {
            details : [],
            order_id : '',
            amount : '',
            time_driver_arrived : '',
            time_driver_dropped : '',
            driver_name : '',
            completed : '',
            cars  : [],
            show_cars : false,
            reg_number : '',
            car_mdel : ''
        }
    },
    methods : {

        async order_details(){
            const res = await axios.get(this.$store.state.api_url+'api/order-details/'+this.order_id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            res.forEach(data => {
                this.amount = data.amount
                this.time_driver_arrived = data.time_driver_arrived
                this.time_driver_dropped = data.time_driver_dropped
                this.driver_name = data.driver_name
                this.completed = data.completed
            });
            this.details = res
        },
        async order_changes(){
            const res = await axios.post(this.$store.state.api_url+'api/order-changes',{
                amount : this.amount,
                time_driver_arrived : this.time_driver_arrived,
                time_driver_dropped : this.time_driver_dropped,
                driver_name : this.driver_name,
                order_id : this.order_id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.order_details()
        },

        async delete_ride(){
            const res = await axios.post(this.$store.state.api_url+'api/delete-ride',{
                order_id : this.order_id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

           this.$router.push('/dashboard')
        },

        async complete_ride(){
            const res = await axios.post(this.$store.state.api_url+'api/complete-ride',{
                order_id : this.order_id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.order_details()
        
        },

        async fetch_cars(){
        const res = await axios.get(this.$store.state.api_url+'api/fetch-cars').then(function(response){
                console.log(response.data)
                return response.data
            }).catch(function(error){
                console.log(error)
            }) 

            this.cars = res
    },

    async allocate_car(reg_number,car_model){
        this.reg_number = reg_number
        this.car_model = car_model
        const res = await axios.post(this.$store.state.api_url+'api/allocate-car',{
                reg_number : this.reg_number,
                car_model : this.car_model,
                order_id : this.order_id

            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.order_details()
            this.show_cars = false

    }


    },
    created(){
        this.order_id=this.$route.params.order_id;
        this.order_details()
        this.fetch_cars()
    }
}
    


</script>


<style scoped>
.box{
    border: solid 1px lightgrey;
}
</style>