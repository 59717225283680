<template>
    <section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <div class="shadow-lg p-3 mb-5 bg-body rounded">
                    <h4>Trips</h4>
                    <table class="table">
                        <thead> <tr> <th>Pickup location</th> <th>Destination</th> <th>Client name</th> <th>Client phone</th> <th>Client email</th> <th>Date</th> <th>Action</th> </tr> </thead>

                        <tbody v-for="order in orders">
                            <tr> <td> {{ order.pickup_location }} </td> <td> {{ order.destination }} </td> <td> {{ order.first_name }} {{ order.last_name }} </td> <td> {{ order.phone }} </td> <td> {{ order.email }} </td> <td> {{ order.created }} </td> <td> <router-link :to="{ name : 'order-details', params : {order_id : order.id} }"> <i class="bi bi-eye"></i> </router-link> </td> </tr>
                        </tbody>

                    </table>
                </div>

            </div>
        </div>
    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'Dashboard',
    data() {
        return {
            orders : [],
        }
    },
    methods : {

        async get_orders(){
            const res = await axios.get(this.$store.state.api_url+'api/get-orders').then(function(response){
                //console.log(response.data)
                return response.data
            }).catch(function(error){
                //console.log(error)
            })

            this.orders = this.orders.concat(res)
        }
    },
    created(){
        
        this.get_orders()
    }
}
    


</script>

