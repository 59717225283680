<template>
    <section>
    <div class="container">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">


                <div style="padding-top:30px; text-align: center;" class="shadow-lg p-3 mb-5 bg-body rounded">
                   <form @submit.prevent="get_location()">
                   <div>
                    <input type="text" class="form-control input-design" v-model="pickup_location" placeholder="Enter your exact pickup location" :class="{ show_error : show_error }">
                   </div>

                   <div style="padding-top:30px;">
                    <input type="text" class="form-control input-design" v-model="destination" placeholder="Enter your destination" :class="{ show_error : show_error }">
                   </div>

                   <div class="row">
                    <div class="col-md-6">
                        <input type="text" class="form-control input-design" v-model="passengers" placeholder="No. of passengers" :class="{ show_error : show_error }">
                    </div>

                    <div class="col-md-6">
                        <input type="text" class="form-control input-design" v-model="luggages" placeholder="No. of luggages" :class="{ show_error : show_error }">
                    </div>
                   </div>


                   <div class="col-md-12" style="padding-top: 10px;">
                    <label>Pickup time(+30 minutes)</label>
                        <input type="time" class="form-control input-design" v-model="pickup_time" placeholder="Pickup time" :class="{ show_error : show_error }">
                    </div>

                    <div style="text-align: center;">
                    <p>Choose a car</p>
                </div>

                <div class="row">
                    <div class="col-md-4 col-4" v-for="car in cars">
                        <a class="btn" @click="pick_car(car.category_name)">
                            <img v-bind:src="'assets/images/'+car.image" class="cab-image">
                            {{ car.category_name }}
                        </a>
                    </div>

                </div>
                 

                   <div style="padding-top:20px;">
                    <button class="btn theme-btn">Reserve</button>
                   </div>


                   </form> 
                </div>


            </div>
            <div class="col-md-4"></div>
        </div>
    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'Home',
    data() {
        return {
            pickup_location : '',
            destination : '',
            show_error : false,
            cars : [],
            car_type : '',
            passengers : '',
            luggages : '',
            pickup_time : ''
        }
    },
    methods : {
        get_location(){
            if(!this.pickup_location){
                this.show_error = true
                return
            }

            if(!this.destination){
                this.show_error = true
                return
            }

            if(!this.passengers){
                this.show_error = true
                return
            }

            if(!this.luggages){
                this.show_error = true
                return
            }

            if(!this.pickup_time){
                this.show_error = true
                return
            }

            if(!this.car_type){
                alert('Choose a car')
                return
            }

        



            this.show_error = false

            localStorage.setItem('pickup_location',this.pickup_location)
            localStorage.setItem('destination',this.destination)
            localStorage.setItem('passengers',this.passengers)
            localStorage.setItem('luggages',this.luggages)
            localStorage.setItem('pickup_time',this.pickup_time)
            localStorage.setItem('token',Date.now())
            this.$router.push('/confirming-order')
        },


        async fetch_cars(){
        const res = await axios.get(this.$store.state.api_url+'api/fetch-car-categories').then(function(response){
                console.log(response.data)
                return response.data
            }).catch(function(error){
                console.log(error)
            }) 

            this.cars = res
    },

    pick_car(car_type){
        this.car_type = car_type
        localStorage.setItem('car_type',car_type)
    },

    minimum_time(){
const now = new Date();
const minutesToAdd = 30;
now.setMinutes(now.getMinutes() + minutesToAdd);
const newTime = now.toLocaleTimeString();
return newTime
    },



    },

    created(){
        
        this.fetch_cars()
        if(!localStorage.getItem('logged_in')){
            this.$router.push('/login')
        }
    }

}
    


</script>

<style scoped>

.show_error{
    border-bottom: solid 1px red;
}

.cab-image{
    width: 100%;
    border: solid 2px lightgrey;
    padding: 10px;
    border-radius: 10px;
}

.cab-image:hover{
    border: solid 2px grey;
    padding: 10px;
    border-radius: 10px;
}

</style>