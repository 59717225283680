import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store';


import '../public/assets/css/bs.min.css'
import '../public/assets/css/style.css'
import '../public/assets/js/bs.min.js'



createApp(App).
use(router).
use(store).
mount('#app')
