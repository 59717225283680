<template>
    <section class="banner" style="background-image: url('assets/images/s1.jpg');">
    <div class="container">
        <div class="row">
           
            <div class="col-md-6">

                <div style="background-color: #800; padding: 20px; border-radius: 20px;">
                    <h1 style="color:#fff; font-size: 8vw; font-weight: 800;">Sizemore Taxi</h1>
                <p style="color:#fff; font-size: 30px;">Always there,whenever you are ready</p>

                <div>
                    <router-link to="/login" class="btn theme-btn-3">Take a Ride</router-link>
                </div>
                </div>

            </div>
            
        </div>
    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'LandingPage',

   

}
    


</script>

<style scoped>
.banner{
    padding-top: 100px;
    padding-bottom: 100px;
    background-size: cover;
    background-position: left;
}
</style>