<template>
    <section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                
                <div class="shadow-lg p-3 mb-5 bg-body rounded">
                    <h4>Accounts</h4>
                    <table class="table">
                        <thead> <tr> <th>Name</th> <th>Email</th> <th>Phone</th> <th>Communication</th> <th>Action</th>  </tr> </thead>

                        <tbody v-for="account in accounts">
                            <tr> <td> {{ account.first_name }} {{ account.last_name }} </td> <td> {{ account.email }} </td> <td> {{ account.phone }} </td> <td> {{ account.mode_of_communication }} </td> <td> <button class="btn theme-btn-2" @click="delete_account(account.id)">Remove</button> </td>   </tr>
                        </tbody>

                    </table>
                </div>

            </div>
        </div>
    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'Accounts',
    data() {
        return {
            accounts : [],
        }
    },
    methods : {

        async clients(){
            const res = await axios.get(this.$store.state.api_url+'api/get-accounts').then(function(response){
                //console.log(response.data)
                return response.data
            }).catch(function(error){
                //console.log(error)
            })

            this.accounts = res
        },

        async delete_account(id){
        const res = await axios.post(this.$store.state.api_url+'api/delete-account',{
            id : id
        }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            }) 

            this.clients()
    }
    },
    created(){
        
        this.clients()
    }
}
    


</script>

