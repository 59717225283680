<template>
    <section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                
                <div class="shadow-lg p-3 mb-5 bg-body rounded">
                   
                    <div class="row">
                        <div class="col-md-6">
                            <h4>Cars</h4>
                        </div>

                        <div class="col-md-6"  style="text-align: right;">
                            <button @click="show_form=!show_form" class="btn theme-btn-2">Add new</button>
                        </div>
                    </div>

                    <form @submit.prevent="add_car()" v-if="show_form">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Registration number</label>
                                <input type="text" v-model="reg_number" class="form-control" :class="{ show_error : show_error}">
                            </div>

                            <div class="col-md-6">
                                <label>Car model</label>
                                <input type="text" v-model="car_model" class="form-control" :class="{show_error : show_error}">
                            </div>

                            <div class="col-md-12">
                                <label>Type of car</label>
                                <select class="form-select" v-model="car_type">
                                    <option>Premium Taxi</option>
                                    <option>Executive Sedan</option>
                                    <option>Multipurpose XL</option>
                                </select>
                            </div>

                            <div class="col-md-12" style="padding-top:20px;">
                                <button class="btn theme-btn" type="submit">Submit</button>
                            </div>

                        </div>
                    </form>



                    <div>
                        <table class="table">
                            <thead>
                                <tr> <th>Reg number</th> <th>Car model</th> <th>Car type</th> <th>Action</th> </tr>
                            </thead>

                            <tbody v-for="car in cars">
                                <tr> <td> {{ car.reg_number }} </td> <td> {{ car.car_model }} </td> <td> {{ car.car_type }} </td> <td> <button class="btn theme-btn-2" @click="delete_car(car.id)">Remove</button> </td> </tr>
                            </tbody>

                        </table>
                    </div>


                    <div>
                        <button class="btn theme-btn-2" @click="show_categories=!show_categories" >Show car categories</button>
                        <button @click="show_categories=!show_categories" class="btn theme-btn-2">New car category</button>
                    </div>


                    <div v-if="!show_categories" style="padding-top: 20px;">
                        <h4>New Category</h4>
                        <form @submit.prevent="new_category()">
                            <label>Category name</label>
                            <input type="text" v-model="category_name" class="form-control" :class=" { show_error:show_error } ">
                            <div style="padding-top: 10px;">
                                <button class="btn theme-btn-2">Submit</button>
                            </div>
                        </form>
                    </div>



                    <div style="padding-top: 20px;" v-if="show_categories">
                        <h4>Car Categories</h4>

                        <table class="table">
                            <tbody v-for="category in car_categories">
                                <tr> <td> {{ category.category_name }} </td> <td> <button class="btn theme-btn-2" @click="delete_category(category.id)">Remove</button> </td> </tr>
                            </tbody>
                        </table>

                    </div>

                 
                </div>

            </div>
        </div>
    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'Cars',
    data () {
        return {
            reg_number : '',
            car_model : '',
            car_type : '',
            show_error : false,
            cars : [],
            show_form : false,
            category_name : '',
            car_categories : [],
            show_categories : true
        }
    },

    methods : {
    async add_car(){
        if(!this.reg_number){
            this.show_error = true
            return
        }

        if(!this.car_model){
            this.show_error = true
            return
        }

        if(!this.car_type){
            this.show_error = true
            return
        }

        this.show_error = false

        const res = await axios.post(this.$store.state.api_url+'api/add-car',{
                reg_number : this.reg_number,
                car_model : this.car_model,
                car_type : this.car_type
            }).then(function(response){
                console.log(response.data)
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.fetch_cars()

    },

    async fetch_cars(){
        const res = await axios.get(this.$store.state.api_url+'api/fetch-cars').then(function(response){
                console.log(response.data)
                return response.data
            }).catch(function(error){
                console.log(error)
            }) 

            this.cars = res
    },

    async new_category(){
        if(!this.category_name){
            this.show_error=true
            return
        }
        this.show_error = false
        const res = await axios.post(this.$store.state.api_url+'api/new-car-category',{
            category_name : this.category_name
        }).then(function(response){
                console.log(response.data)
                return response.data
            }).catch(function(error){
                console.log(error)
            }) 
            this.fetch_categories()
            this.show_categories = true
    },
    async fetch_categories(){
        const res = await axios.get(this.$store.state.api_url+'api/fetch-car-categories').then(function(response){
                console.log(response.data)
                return response.data
            }).catch(function(error){
                console.log(error)
            }) 

            this.car_categories = res
    },
    async delete_category(id){
        const res = await axios.post(this.$store.state.api_url+'api/delete-car-category',{
            id : id
        }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            }) 

            this.fetch_categories()
    },
    async delete_car(id){
        const res = await axios.post(this.$store.state.api_url+'api/delete-car',{
            id : id
        }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            }) 

            this.fetch_cars()
    }

    },

    created(){
        this.fetch_cars()
        this.fetch_categories()
    }

}

</script>


<style scoped>
.show_error{
    border: solid 1px red;
}
</style>
