import {createRouter,createWebHistory} from 'vue-router'


import Home from '../components/Home'
import ConfirmingOrder from '../components/ConfirmingOrder'
import Success from '../components/Success'
import Login from '../components/Login'
import Register from '../components/Register'
import AdminLogin from '../components/AdminLogin'
import Dashboard from '../components/Dashboard'
import OrderDetails from '../components/OrderDetails'
import Trips from '../components/Trips'
import MyTrips from '../components/MyTrips'
import Cars from '../components/Cars'
import Accounts from '../components/Accounts'
import LandingPage from '../components/LandingPage'
import How from '../components/How'

const routes = [

  {
     path: '/home',
     name : 'home',
     component : Home
     },

     {
        path: '/confirming-order',
        name : 'confirming-order',
        component : ConfirmingOrder
        },

        {
            path: '/success',
            name : 'success',
            component : Success
            },

            {
                path: '/login',
                name : 'login',
                component : Login
                },


            {
                path: '/register',
                name : 'register',
                component : Register
                },

                {
                    path: '/admin-login',
                    name : 'admin-login',
                    component : AdminLogin
                    },

                {
                path: '/dashboard',
                name : 'dashboard',
                component : Dashboard
                },

                {
                    path: '/order-details/:order_id',
                    name : 'order-details',
                    component : OrderDetails
                    },

                    {
                        path: '/trips',
                        name : 'trips',
                        component : Trips
                        },

            {
            path: '/my-trips',
            name : 'my-trips',
            component : MyTrips
            },

            {
                path: '/cars',
                name : 'cars',
                component : Cars
                },

                {
                    path: '/accounts',
                    name : 'accounts',
                    component : Accounts
                    },

                    {
                        path: '/',
                        name : 'landing-page',
                        component : LandingPage
                        },

                        {
                            path: '/how',
                            name : 'how',
                            component : How
                            },

           
     
]


const router = createRouter({
    history : createWebHistory(process.env.BASE_URL),
    routes: routes,
})


export default router


