<template>
    <section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <div class="shadow-lg p-3 mb-5 bg-body rounded">
                   
                    <div style="padding-bottom: 10px;">
                        <router-link to="/" class="btn theme-btn">Go Back</router-link>
                    </div>

                    <h4>My Trips</h4>

                    <div class="row box" v-for="order in orders">

                        <div class="col-md-3 col-6">
                            <p>Pickup location <i class="bi bi-geo text-danger"></i></p>
                            <h6> {{ order.pickup_location }} </h6>
                        </div>

                        <div class="col-md-3 col-6">
                            <p>Destination <i class="bi bi-geo text-success"></i></p>
                            <h6> {{ order.destination }} </h6>
                        </div>

                        <div class="col-md-3 col-6">
                            <p>Amount <i class="bi bi-cash text-warning"></i></p>
                            <h6>Ksh. {{ order.amount }} </h6>
                        </div>

                        <div class="col-md-3 col-6">
                            <p> Driver <i class="bi bi-person-circle text-warning"></i></p>
                            <h6> {{ order.driver_name }} </h6>
                        </div>

                    </div>

                    <div v-if="orders.length==0">
                        <p>You don't have any trips</p>
                        <router-link to="/" class="btn theme-btn-2">Order trip</router-link>
                    </div>

                </div>

            </div>
        </div>
    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'MyTrips',
    data() {
        return {
            orders : [],
            user_id : localStorage.getItem('user_id')
        }
    },
    methods : {

        async get_orders(){
            const res = await axios.get(this.$store.state.api_url+'api/my-trips/'+this.user_id).then(function(response){
                //console.log(response.data)
                return response.data
            }).catch(function(error){
                //console.log(error)
            })

            this.orders = res
        }
    },
    created(){
        this.get_orders()
       
    }
}
    


</script>

<style scoped>
.box{
    border:solid 1px lightgrey;
    margin-top: 10px;
    border-radius: 5px;
}

</style>