//import Vue from 'vue'
import Vuex from 'vuex'


export default new Vuex.Store({
  state: {
    api_url: 'https://server.sizemorechauffers.com/',
    logged_in : localStorage.getItem('logged_in'),
    user_type : localStorage.getItem('user_type')
    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})