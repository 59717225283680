<template>
    <section>
    <div class="container">
        <div class="row">
           
            <div class="col-md-12" style="text-align: center; padding-bottom: 30px;">
                <h1 style="font-size: 4vw; font-weight: 800;">How It Works</h1>
            </div>

            <div class="col-md-6">
                <img src="assets/images/s2.jpg" style="width: 100%; border-right: solid 4px #800;">
            </div>

            <div class="col-md-6">

                <div class="steps">
                    <p> <i class="bi bi-check-circle"></i> Sign up or log in using your credentials</p>
                </div>

                <div class="steps">
                    <p><i class="bi bi-check-circle"></i> Set your pickup location and destination</p>
                </div>

                <div class="steps">
                    <p><i class="bi bi-check-circle"></i> Confirm your ride and wait for the rider for 30 minutes</p>
                </div>

                <div class="steps">
                    <p><i class="bi bi-check-circle"></i> Pay and enjoy your ride</p>
                </div>

            </div>
            
        </div>
    </div> 
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name : 'How',

   

}
    


</script>

<style scoped>
.steps{
border: solid 2px #F1F1F1;
padding: 20px;
color: #800;
margin-top: 10px;
}
</style>